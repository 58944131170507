<template>
	<div id="content">
		<Header v-bind:infos="infos" />
		<main class="projets">
			<!--Projet Vocabulary List-->
			<div class="container">
				<div class="row">
					<div class="col-sm-8 col-md-8 col-lg-8 col-xl-8">
						<div class="hoverImage">
							<router-link to="vocabularylist"
								><img
									class="img-fluid"
									src="../assets/projets/fond_vocabulary_list.jpg"
									alt="Image du site Vocabulary List"
							/></router-link>
						</div>
					</div>
				</div>
				<div class="row">
					<router-link
						to="vocabularylist"
						class="titreProjetAccueil col-sm-8 col-md-8 col-lg-8 col-xl-8"
						><h3>Vocabulary List - Site web (2022)</h3></router-link
					>
				</div>
				<div class="row">
					<router-link
						to="vocabularylist"
						class="descriptifProjetAccueil col-sm-8 col-md-8 col-lg-8 col-xl-8"
						><p>
							Création d'un gestionnaire de vocabulaire personnel
						</p></router-link
					>
				</div>
			</div>
			<!--Projet Cron Manager-->
			<div class="container">
				<div class="row">
					<div
						class="offset-sm-2 offset-md-2 offset-lg-2 offset-xl-2 col-sm-8 col-md-8 col-lg-8 col-xl-8"
					>
						<div class="hoverImage">
							<router-link to="cronmanager"
								><img
									class="img-fluid"
									src="../assets/projets/fond_cron_manager.jpg"
									alt="Image de l'application Discord"
							/></router-link>
						</div>
					</div>
				</div>
				<div class="row">
					<router-link
						to="cronmanager"
						class="titreProjetAccueil offset-sm-2 offset-md-2 offset-lg-2 offset-xl-2 col-sm-8 col-md-8 col-lg-8 col-xl-8"
						><h3>Cron manager - BOT Discord (2022)</h3></router-link
					>
				</div>
				<div class="row">
					<router-link
						to="cronmanager"
						class="descriptifProjetAccueil offset-sm-2 offset-md-2 offset-lg-2 offset-xl-2 col-sm-8 col-md-8 col-lg-8 col-xl-8"
						><p>
							Création d'un BOT Discord
						</p></router-link
					>
				</div>
			</div>
			<!--Projet Random Number to Letter Generator-->
			<div class="container">
				<div class="row">
					<div
						class="offset-sm-4 offset-md-4 offset-lg-4 offset-xl-4 col-sm-8 col-md-8 col-lg-8 col-xl-8"
					>
						<div class="hoverImage">
							<router-link to="randomnumbertolettergenerator"
								><img
									class="img-fluid"
									src="../assets/projets/fond_random_number_to_letter_generator.jpg"
									alt="Image du projet Random Number to Letter Generator"
							/></router-link>
						</div>
					</div>
				</div>
				<div class="row">
					<router-link
						to="randomnumbertolettergenerator"
						class="titreProjetAccueil offset-sm-4 offset-md-4 offset-lg-4 offset-xl-4 col-sm-8 col-md-8 col-lg-8 col-xl-8"
						><h3>
							Random Number to Letter Generator - Site web (2022)
						</h3></router-link
					>
				</div>
				<div class="row">
					<router-link
						to="randomnumbertolettergenerator"
						class="descriptifProjetAccueil offset-sm-4 offset-md-4 offset-lg-4 offset-xl-4 col-sm-8 col-md-8 col-lg-8 col-xl-8"
						><p>
							Création d'un générateur de nombre en lettre aléatoire
						</p></router-link
					>
				</div>
			</div>
			<!--Projet Marc Correcteur-->
			<div class="container">
				<div class="row">
					<div
						class="offset-sm-2 offset-md-2 offset-lg-2 offset-xl-2 col-sm-8 col-md-8 col-lg-8 col-xl-8"
					>
						<div class="hoverImage">
							<router-link to="marccorrecteur"
								><img
									class="img-fluid"
									src="../assets/projets/fond_marc_correcteur.jpg"
									alt="Image du site Marc Correcteur"
							/></router-link>
						</div>
					</div>
				</div>
				<div class="row">
					<router-link
						to="marccorrecteur"
						class="titreProjetAccueil offset-sm-2 offset-md-2 offset-lg-2 offset-xl-2 col-sm-8 col-md-8 col-lg-8 col-xl-8"
						><h3>Marc Correcteur - Site web (2021)</h3></router-link
					>
				</div>
				<div class="row">
					<router-link
						to="marccorrecteur"
						class="descriptifProjetAccueil offset-sm-2 offset-md-2 offset-lg-2 offset-xl-2 col-sm-8 col-md-8 col-lg-8 col-xl-8"
						><p>
							Création de site web pour un particulier
						</p></router-link
					>
				</div>
			</div>
			<!--Projet All In Box-->
			<div class="container">
				<div class="row">
					<div class="col-sm-8 col-md-8 col-lg-8 col-xl-8">
						<div class="hoverImage">
							<router-link to="allinbox"
								><img
									class="img-fluid"
									src="../assets/projets/fond_all_in_box.jpg"
									alt="Image du site All In Box"
							/></router-link>
						</div>
					</div>
				</div>
				<div class="row">
					<router-link
						to="allinbox"
						class="titreProjetAccueil col-sm-8 col-md-8 col-lg-8 col-xl-8"
						><h3>All In Box - Module d'e-réputation (2021)</h3></router-link
					>
				</div>
				<div class="row">
					<router-link
						to="allinbox"
						class="descriptifProjetAccueil col-sm-8 col-md-8 col-lg-8 col-xl-8"
						><p>
							Projet de stage de Licence Professionnelle CIASIE
						</p></router-link
					>
				</div>
			</div>
			<!--Projet Racing Maze-->
			<div class="container">
				<div class="row">
					<div
						class="offset-sm-2 offset-md-2 offset-lg-2 offset-xl-2 col-sm-8 col-md-8 col-lg-8 col-xl-8"
					>
						<div class="hoverImage">
							<router-link to="racingmaze"
								><img
									class="img-fluid"
									src="../assets/projets/fond_racing_maze.jpg"
									alt="Image du projet Racing Maze"
							/></router-link>
						</div>
					</div>
				</div>
				<div class="row">
					<router-link
						to="racingmaze"
						class="titreProjetAccueil offset-sm-2 offset-md-2 offset-lg-2 offset-xl-2 col-sm-8 col-md-8 col-lg-8 col-xl-8"
						><h3>
							Racing Maze - Application web de gaming (2020-2021)
						</h3></router-link
					>
				</div>
				<div class="row">
					<router-link
						to="racingmaze"
						class="descriptifProjetAccueil offset-sm-2 offset-md-2 offset-lg-2 offset-xl-2 col-sm-8 col-md-8 col-lg-8 col-xl-8"
						><p>
							Projet collectif pour la Licence Professionnelle CIASIE
						</p></router-link
					>
				</div>
			</div>
			<!--Projet Conseil Départemental du Haut-Rhin-->
			<div class="container">
				<div class="row">
					<div
						class="offset-sm-4 offset-md-4 offset-lg-4 offset-xl-4 col-sm-8 col-md-8 col-lg-8 col-xl-8"
					>
						<div class="hoverImage">
							<router-link to="conseildepartementalhautrhin"
								><img
									class="img-fluid"
									src="../assets/projets/fond_conseil_departemental_haut_rhin.jpg"
									alt="Photo du Conseil Départemental du Haut-Rhin"
							/></router-link>
						</div>
					</div>
				</div>
				<div class="row">
					<router-link
						to="conseildepartementalhautrhin"
						class="titreProjetAccueil offset-sm-4 offset-md-4 offset-lg-4 offset-xl-4 col-sm-8 col-md-8 col-lg-8 col-xl-8"
						><h3>
							Conseil Départemental du Haut-Rhin - Création d'applications
							(2020)
						</h3></router-link
					>
				</div>
				<div class="row">
					<router-link
						to="conseildepartementalhautrhin"
						class="descriptifProjetAccueil offset-sm-4 offset-md-4 offset-lg-4 offset-xl-4 col-sm-8 col-md-8 col-lg-8 col-xl-8"
						><p>
							Projet de stage pour le DUT MMI
						</p></router-link
					>
				</div>
			</div>
			<!--Projet Open Access-->
			<div class="container">
				<div class="row">
					<div
						class="offset-sm-2 offset-md-2 offset-lg-2 offset-xl-2 col-sm-8 col-md-8 col-lg-8 col-xl-8"
					>
						<div class="hoverImage">
							<router-link to="openaccess"
								><img
									class="img-fluid"
									src="../assets/projets/fond_open_access.jpg"
									alt="Photo du projet Open Access"
							/></router-link>
						</div>
					</div>
				</div>
				<div class="row">
					<router-link
						to="openaccess"
						class="titreProjetAccueil offset-sm-2 offset-md-2 offset-lg-2 offset-xl-2 col-sm-8 col-md-8 col-lg-8 col-xl-8"
						><h3>Open Access - Webdocumentaire (2019-2020)</h3></router-link
					>
				</div>
				<div class="row">
					<router-link
						to="openaccess"
						class="descriptifProjetAccueil offset-sm-2 offset-md-2 offset-lg-2 offset-xl-2 col-sm-8 col-md-8 col-lg-8 col-xl-8"
						><p>
							Projet collectif commandité par l'Union Européenne
						</p></router-link
					>
				</div>
			</div>

			<!--Projet Tokyo Ghoul-->
			<div class="container">
				<div class="row">
					<div class="col-sm-8 col-md-8 col-lg-8 col-xl-8">
						<div class="hoverImage">
							<router-link to="tokyoghoul"
								><img
									class="img-fluid"
									src="../assets/projets/fond_tokyo_ghoul.jpg"
									alt="Image du projet Tokyo Ghoul | Us all en Motion Design"
							/></router-link>
						</div>
					</div>
				</div>
				<div class="row">
					<router-link
						to="tokyoghoul"
						class="titreProjetAccueil col-sm-8 col-md-8 col-lg-8 col-xl-8"
						><h3>Tokyo Ghoul | Us all - Motion Design (2019)</h3></router-link
					>
				</div>
				<div class="row">
					<router-link
						to="tokyoghoul"
						class="descriptifProjetAccueil col-sm-8 col-md-8 col-lg-8 col-xl-8"
						><p>
							Travail individuel dans le cadre d'un travail éducatif en MMI de
							Montbéliard
						</p></router-link
					>
				</div>
			</div>

			<!--Projet L.A.R.D-->
			<div class="container">
				<div class="row">
					<div
						class="offset-sm-2 offset-md-2 offset-lg-2 offset-xl-2 col-sm-8 col-md-8 col-lg-8 col-xl-8"
					>
						<div class="hoverImage">
							<router-link to="lard"
								><img
									class="img-fluid"
									src="../assets/projets/fond_lard.jpg"
									alt="Image du projet du LARD"
							/></router-link>
						</div>
					</div>
				</div>
				<div class="row">
					<router-link
						to="lard"
						class="titreProjetAccueil offset-sm-4 offset-sm-2 offset-md-2 offset-lg-2 offset-xl-2 col-sm-8 col-md-8 col-lg-8 col-xl-8"
						><h3>L.A.R.D - Refonte graphique (2019)</h3></router-link
					>
				</div>
				<div class="row">
					<router-link
						to="lard"
						class="descriptifProjetAccueil offset-sm-2 offset-md-2 offset-lg-2 offset-xl-2 col-sm-8 col-md-8 col-lg-8 col-xl-8"
						><p>
							Travail individuel pour les Laboratoires Associés de Radiophysique
							et de Dosimétrie
						</p></router-link
					>
				</div>
			</div>

			<!--Projet monuments Belfort Montbéliard-->
			<div class="container">
				<div class="row">
					<div
						class="offset-sm-4 offset-md-4 offset-lg-4 offset-xl-4 col-sm-8 col-md-8 col-lg-8 col-xl-8"
					>
						<div class="hoverImage">
							<router-link to="monumentsbelfortmontbeliard"
								><img
									class="img-fluid"
									src="../assets/projets/fond_monuments_belfort_montbeliard.svg"
									alt="Image du projet des monuments de Belfort et Montbéliard"
							/></router-link>
						</div>
					</div>
				</div>
				<div class="row">
					<router-link
						to="monumentsbelfortmontbeliard"
						class="titreProjetAccueil offset-sm-4 offset-md-4 offset-lg-4 offset-xl-4 col-sm-8 col-md-8 col-lg-8 col-xl-8"
						><h3>
							Monuments de Belfort et Montbéliard - Illustration (2019)
						</h3></router-link
					>
				</div>
				<div class="row">
					<router-link
						to="monumentsbelfortmontbeliard"
						class="descriptifProjetAccueil offset-sm-4 offset-md-4 offset-lg-4 offset-xl-4 col-sm-8 col-md-8 col-lg-8 col-xl-8"
					>
						<p>
							Travail individuel dans le cadre d'un travail éducatif en MMI de
							Montbéliard
						</p></router-link
					>
				</div>
			</div>

			<!--Projet Chronos-->
			<div class="container">
				<div class="row">
					<div
						class="offset-sm-2 offset-md-2 offset-lg-2 offset-xl-2 col-sm-8 col-md-8 col-lg-8 col-xl-8"
					>
						<div class="hoverImage">
							<router-link to="chronos"
								><img
									class="img-fluid"
									src="../assets/projets/fond_chronos.jpg"
									alt="Image du projet Chronos"
							/></router-link>
						</div>
					</div>
				</div>
				<div class="row">
					<router-link
						to="chronos"
						class="titreProjetAccueil offset-sm-2 offset-md-2 offset-lg-2 offset-xl-2 col-sm-8 col-md-8 col-lg-8 col-xl-8"
						><h3>Chronos - Site web mobile first (2018)</h3></router-link
					>
				</div>
				<div class="row">
					<router-link
						to="chronos"
						class="descriptifProjetAccueil offset-sm-2 offset-md-2 offset-lg-2 offset-xl-2 col-sm-8 col-md-8 col-lg-8 col-xl-8"
						><p>
							Projet individuel dans le cadre d'un travail éducatif en MMI de
							Montbéliard
						</p></router-link
					>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
	metaInfo: function() {
		return {
			title: "Antonin Winterstein | Projets",
			meta: [
				{
					name: "description",
					content:
						"Vous trouverez ici tous les différents travaux d’Antonin Winterstein.",
				},
			],
		};
	},
	components: {
		Header,
	},
	data() {
		return {
			infos: {
				titrePage: "PROJETS",
				urlImage: require("../assets/projets/fond_projets.jpg"),
			},
		};
	},
	mounted() {
		window.scrollTo(0, 0);
	},
};
</script>

<style lang="scss">
main.projets {
	margin-top: 3vh;
}

html {
	body {
		main {
			// Enlever les marges pour ceux qui ont des offset
			div.container div.row div .hoverImage {
				margin: 0;
			}
		}
	}
}
</style>
